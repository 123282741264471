import React from 'react';
import {useRecoilState} from 'recoil';
import {collapsedState} from '../../utils/recoil-atoms';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

const Navbar = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    },[]);

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link 
                                to="/"
                                onClick={() => setCollapsed(true)} 
                                className="navbar-brand"
                            >
                                {/*<div style={{width:'211px', margin:'auto'}}>*/}
                                <StaticImage src="../../assets/images/logo.png" alt="logo" />
                                {/*</div>*/}
                            </Link>


                            <button 
                                onClick={toggleNavbar} 
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link*/}
                                    {/*        to="/#"*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={e => e.preventDefault()}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    >*/}
                                    {/*        機能 <i className='bx bx-chevron-down'></i>*/}
                                    {/*        <ul className="dropdown-menu">*/}
                                    {/*            <li className="nav-item">*/}
                                    {/*                <Link*/}
                                    {/*                    to="/campaign"*/}
                                    {/*                    activeClassName="active"*/}
                                    {/*                    onClick={() => setCollapsed(true)}*/}
                                    {/*                    className="nav-link"*/}
                                    {/*                >*/}
                                    {/*                    キャンペーン*/}
                                    {/*                </Link>*/}
                                    {/*            </li>*/}
                                    {/*            <li className="nav-item">*/}
                                    {/*                <Link*/}
                                    {/*                    to="/instantwin"*/}
                                    {/*                    activeClassName="active"*/}
                                    {/*                    onClick={() => setCollapsed(true)}*/}
                                    {/*                    className="nav-link"*/}
                                    {/*                >*/}
                                    {/*                    インスタントウィン*/}
                                    {/*                </Link>*/}
                                    {/*            </li>*/}
                                    {/*            <li className="nav-item">*/}
                                    {/*                <Link*/}
                                    {/*                    to="/gallery"*/}
                                    {/*                    activeClassName="active"*/}
                                    {/*                    onClick={() => setCollapsed(true)}*/}
                                    {/*                    className="nav-link"*/}
                                    {/*                >*/}
                                    {/*                    ギャラリー*/}
                                    {/*                </Link>*/}
                                    {/*            </li>*/}
                                    {/*        </ul>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link*/}
                                    {/*      to="/examples"*/}
                                    {/*      activeClassName="active"*/}
                                    {/*      onClick={() => setCollapsed(true)}*/}
                                    {/*      className="nav-link"*/}
                                    {/*    >*/}
                                    {/*        事例・サンプル*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link*/}
                                    {/*        to="/pricing"*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={() => setCollapsed(true)}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    >*/}
                                    {/*        料金*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link*/}
                                    {/*        to="/documents"*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={() => setCollapsed(true)}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    >*/}
                                    {/*        使い方*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link*/}
                                    {/*        to="/about-us"*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={() => setCollapsed(true)}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    >*/}
                                    {/*        会社情報*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <AnchorLink*/}
                                    {/*        to="/#question"*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={() => setCollapsed(true)}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    >*/}
                                    {/*        よくあるご質問*/}
                                    {/*    </AnchorLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link*/}
                                    {/*        to="/contact"*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={() => setCollapsed(true)}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    >*/}
                                    {/*        お問い合わせ*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                </ul>

                                {/*<div className="others-option d-flex align-items-center">*/}
                                    {/*<div className="option-item">*/}
                                    {/*    <form className="search-box">*/}
                                    {/*        <input type="text" className="input-search" placeholder="Search for anything" />*/}
                                    {/*        <button type="submit">*/}
                                    {/*            <i className="flaticon-loupe"></i>*/}
                                    {/*        </button>*/}
                                    {/*    </form>*/}
                                    {/*</div>*/}

                                {/*    <div className="option-item">*/}
                                {/*        <Link*/}
                                {/*            href="/signin"*/}
                                {/*            activeClassName="active"*/}
                                {/*            onClick={() => setCollapsed(true)}*/}
                                {/*            className="default-btn"*/}
                                {/*            style={{color:'white'}}*/}
                                {/*        >*/}
                                {/*            <i className="flaticon-right"></i> ログイン <span></span>*/}
                                {/*        </Link>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export const query = graphql`
  query {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 211, height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Navbar;
