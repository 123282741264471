import React from 'react';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <Link to="/" className="logo">
                                <StaticImage src="../../assets/images/logo.png" alt="logo" />
                            </Link>

                            <ul className="social-link">
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Explore</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about-us">
                                        会社情報
                                    </Link>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/blog">*/}
                                {/*        ブログ*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li>
                                    <Link to="/privacy">
                                        プライバシーポリシー
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-service">
                                        利用規約
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/legal">
                                        特定商取引に基づく表記
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/jobs">
                                        採用情報
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Address</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i>
                                    〒541-0053<br/>
                                    大阪府大阪市中央区本町4-4-17 <br /> RE-012 304
                                </li>
                                {/*<li>*/}
                                {/*    <i className='bx bx-phone-call'></i>*/}
                                {/*    <a href="tel:+44587154756">+1 (123) 456 7890</a>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <i className='bx bx-envelope'></i>*/}
                                {/*    <a href="mailto:hello@rewy.com">contact@retach.biz</a>*/}
                                {/*</li>*/}
                                {/*        <li>
                                 <i className='bx bxs-inbox'></i>
                                 <a href="tel:+557854578964">+55 785 4578964</a>
                                 </li>*/}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            {/*<h3>Dashboard</h3>*/}

                            {/*<ul className="footer-links-list">*/}
                            {/*    <li>*/}
                            {/*        <Link href="/signin">*/}
                            {/*            ログイン*/}
                            {/*        </Link>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <Link href="/signup">*/}
                            {/*            アカウント登録*/}
                            {/*        </Link>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <Link to="/documents">*/}
                            {/*            使い方*/}
                            {/*        </Link>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>

                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>Retach inc.</strong> All rights reserved </p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <StaticImage src="../../assets/images/footer-map.png" alt="footer-logo" />
            </div>
        </footer>
    );
};

export default Footer;